import * as React from 'react';
import './learnpmx.scss';
import { Headline } from 'components/headline/headline';
import { NeedSwapDrawer } from 'components/drawer/drawer';
import { useNavigate } from 'react-router-dom';
import { BackButton } from 'components/button/back/back-button';


type Props = {

}
export const LearnPmxChange: React.FC<Props> = (props) => {

    const [isOpen, setIsOpen] = React.useState<boolean>(true);
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!isOpen) {
            navigate('/')
        }
    }, [isOpen]);
    return (
        <>
            <BackButton />
            <div className={`ns-learn`}>
                <h2>خرید ووچر پرفکت‌مانی بدون نیاز به احراز هویت</h2>
                <p>قبلاً امکان خرید پرفکت مانی به صورت مستقیم بود اما امروزه صرافی‌های معتبر به دلیل <b>محدودیت بانک مرکزی دیگر</b> پرفکت مانی دیگه نمی‌فروشند و از طرفی دیگر سایت‌هایی به اسم پرداخت بین‌المللی ووچر را با احراز هویت به شما می‌فروشند که بسیار خطرناک است.</p>

                <br />
                <p>برای خرید ووچر کافیه که اول از صرافی PMX به صورت قانونی ترون بخرید و به کیف‌پول نیدفورسواپ انتقال بدید تا به صورت اتوماتیک به ووچر تبدیل شود.</p>
                <br />
                <p>۱-در صفحه اصلی سایت PMXchange ارز ترون را انتخاب کرده و مقدار دلاری مدنظر را وارد می‌کنیم.</p>
                <img src="/learn/1.webp" alt="" title='همانطور می‌توانید مبلغ وچری‌را در بخش معادل دلاری ببینید.' />
                <p>۲-مراحل احراز هویت و ثبت‌کارت را پشت‌ سر می‌گذاریم و در صفحه‌ی‌ انتخاب آدرس کیف‌پول برروی گزینه‌ی افزودن آدرس کیف‌پول کلیک می‌کنیم.</p>

                <img src="/learn/2.webp" alt="" title='در صورتی که از قبل کیف پول شما ثبت شده باشد باکس دوم به صورت اتوماتیک پر می‌شود.' />
                <p>۳-در این مرحله به ربات تلگرامی Needforswap با آدرس @needforswapbot می‌رویم و برروی گزینه‌ی استارت یا NFS APPLICATION کلیک می‌کنیم. پس از استارت بر روی گزینه‌ی ساخت دریافت آدرس اختصاصی ترون کلیک کنید.</p>
                <img src="/learn/3.jpg" alt="" title='یا می‌توانید روی گزینه‌ی تبدیل ترون به ووچر استفاده کنید.' />

                <p>۴-پس از کلیک روی دکمه‌ی تبدیل ترون به ووچر با پیام مشابه تصویر زیر روبرو می‌شود و می‌توانید آدرس ولت اختصاصی خود را کپی کنید.</p>
                <img src="/learn/4.jpg" alt="" />
                <p>۵-مجددا وارد سایت PMXchange شده و در بخش عنوان آدرس کیف‌پول اسم ولت اختصاصی ترون را می‌نویسید سپس در کادر پایین آدرس ولت ترونی خود را وارد کنید.</p>
                <img src="/learn/5.webp" alt="" />
                <p>۶-حال خرید خود را به پایان برسانید و ووچر پرفکت‌مانی خود را در ربات نید‌فورسواپ تحویل بگیرید.</p>
                <img src="/learn/6.png" alt="" />
                <p>وچر شما به صورت اتومات به محض انجام تراکنش در اختیار شما قرار میگیرد.</p>
                <img src="/learn/7.jpg" alt="" />
            </div >
        </>
    );

};
