import * as React from 'react';
import './needswap.scss';
import 'styles/global.scss';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Dashboard from './dashboard/dashboard';
import { CoinsList } from 'components/coins-list/coins-list';
import { CreditCard } from 'components/credit-card/credit-card';
import { Coin } from './asset/coin';
import { SignIn } from './user/signin/signin';
import { SignUp } from './user/signup/signup';
import { Deposit } from './exchange/deposit/deposit';
import { TransactionsHistory } from './history/history';
import { Divider } from 'antd';
import { Navbar } from 'components/navbar/navbar';
import { Profile } from './user/profile/profile';
import { Swap } from './exchange/swap/swap';
import { Game } from './game/game';
import { signUp } from 'core/repositories/users/login';
import { UserProfile, UserSignUpModel } from 'core/domains/users/user';
import { useEffect } from 'react';
import { BackButtonCallback, EventCallback, WebAppPopupParams } from 'telegram';
import { Widthrawal } from './exchange/widthrawal/widthrawal';
import { SellPm } from './exchange/sell-pm/sell-pm';
import TelegramLogin from 'components/telegram/login/telegram-login';
import { SwapBox } from 'components/swapbox/swapbox';
import { Lightbulb } from '@phosphor-icons/react/dist/ssr';
import mainBanner from 'assets/images/main-banner.png'
import { MessageOutlined } from '@ant-design/icons';
import { LearnPmxChange } from './learn/pmxchange/learn';

export const NeedSwap: React.FC = () => {




    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/signin"
                    element={<SignIn />}
                />
                <Route
                    path="/signup"
                    element={<SignUp />}
                />
                <Route
                    path="/swap"
                    element={<Dashboard title="صرافی" component={<Swap />} />}
                />
                <Route
                    path="/game"
                    element={<Dashboard title="بازی" component={<Game />} />}
                />
                <Route
                    path="/history"
                    element={<Dashboard title="تاریخچه تراکنش ها" component={<TransactionsHistory />} />}
                />
                <Route
                    path="/profile"
                    element={<Dashboard title="حساب کاربری" component={<Profile />} />}
                />
                <Route
                    path="deposit/:symbol"
                    element={<Dashboard title="واریز ارز" component={<Deposit />} />}
                />
                <Route
                    path="widthrawal/:symbol"
                    element={<Dashboard title="برداشت ارز" component={<Widthrawal />} />}
                />
                <Route
                    path="coin/:symbol"
                    element={<Dashboard title="ارز" component={<Coin />} />}
                />
                <Route
                    path="pm-to-tron"
                    element={<Dashboard title="تبدیل ووچر پرفکت مانی به ترون" component={<SellPm />} />}
                />
                <Route
                    path="tron-to-pm"
                    element={<Dashboard title="تبدیل ترون به ووچر پرفکت مانی" component={<Deposit />} />}
                />
                <Route
                    path="usdt-to-pm"
                    element={<Dashboard title="تبدیل تتر به ووچر پرفکت مانی" component={<Deposit />} />}
                />
                <Route
                    path="learn-pmxchange"
                    element={<Dashboard title=">خرید ووچر پرفکت‌مانی بدون نیاز به احراز هویت" component={<LearnPmxChange />} />}
                />
                <Route
                    path="/"
                    element={<Dashboard title="داشبورد" component={
                        <>
                            <CreditCard />
                            <Divider />
                            <div className="swap-boxes">
                                <SwapBox url='deposit/usdt' source='تــتـر' destination='ووچـــــر' symbol='USDT' />
                                <SwapBox url='deposit/trx' source='ترون' destination='ووچـــــر' symbol='PM' />
                                <SwapBox url='pm-to-tron' source='ووچر' destination='تـــــرون' symbol='TRX' />
                            </div>
                            <div className="learn-row">
                                <Lightbulb />
                                <span>
                                    آموزش خرید ووچر پرفکت مانی در ۳ دقیقه
                                </span>
                            </div>
                            <div onClick={() => window.open(`https://t.me/NFSsupport`)} className="learn-row">
                                <MessageOutlined />
                                <span>
                                    نیاز به پشتبانی دارید؟
                                </span>
                            </div>
                        </>
                    }
                    />}
                />
            </Routes>
        </BrowserRouter>
    );

};
