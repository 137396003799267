import * as React from "react";
import "./back-button.scss";
import { ArrowLeftOutlined, LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

export const BackButton: React.FC<any> = (props) => {
    const navigate = useNavigate();
    return (
        <div onClick={() => navigate(-1)} className="ns-back-button">
            <ArrowLeftOutlined />
            <span>بازگشت</span>
        </div>
    );
};
