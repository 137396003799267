import * as React from 'react';
import './history.scss';
import { Button } from 'components/button/button';
import btc from 'cryptocurrency-icons/svg/color/btc.svg'
import eth from 'cryptocurrency-icons/svg/color/eth.svg'
import ltc from 'cryptocurrency-icons/svg/color/ltc.svg'
import ada from 'cryptocurrency-icons/svg/color/ada.svg'
import usdt from 'cryptocurrency-icons/svg/color/usdt.svg'
import sol from 'cryptocurrency-icons/svg/color/sol.svg'
import { Link, useNavigate, useNavigation } from 'react-router-dom';
import { ArrowDownOutlined, ArrowUpOutlined, DownloadOutlined, PieChartOutlined, UploadOutlined } from '@ant-design/icons';
import { PieChart } from 'react-minimal-pie-chart';
import { DepositIcon, DepositIconLight, EmptyIcon, InfoIcon, SwapIcon, SwapIconLight, WidthrawlIcon, WidthrawlIconLight } from 'assets/icons/svgs/icons';
import { Headline } from 'components/headline/headline';
import { NeedSwapDrawer } from 'components/drawer/drawer';
import { getMineOrders } from 'core/repositories/orders/order';
import { Order, OrderList } from 'core/domains';
import { Empty } from 'components/empty/empty';
import { getIconPath } from 'utils/scripts/market';
import moment from 'jalali-moment'
import { Avatar, Card, Skeleton, Spin } from 'antd';

type Props = {

}



export const TransactionsHistory: React.FC<Props> = (props) => {

    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [selected, setSelected] = React.useState<Order>();
    const [loading, setLoading] = React.useState<any>(false);
    const [orders, setOrders] = React.useState<Order[]>();


    const generateDate = () => {
        moment().locale('fa')
    }
    React.useEffect(() => {
        getMineOrders(setLoading, setOrders)
    }, []);
    return (
        <>
            <NeedSwapDrawer
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                content={
                    <>
                        <div className="transaction-details-drawer">
                            {
                                selected &&
                                <>
                                    <div className="market-info">
                                        <img src={getIconPath(selected.sourceMarket)} alt="" />
                                        <img src={getIconPath(selected.destinationMarket)} alt="" />
                                    </div>
                                    <div className="swap-info">
                                        <span className='source'>
                                            -{selected.sourceAmount} {selected.sourceMarket}
                                        </span>
                                        <span className='destination'>
                                            +{selected.destinationAmount} {selected.destinationMarket}
                                        </span>
                                        <span className='amount'>
                                            ${selected.sourceAmountInDollar}
                                        </span>
                                        <span className='date'>

                                            تبدیل شده در {moment(selected.createdOn).locale('fa').format('jDD jMMMM jYYYY ساعت HH:mm')}
                                        </span>
                                    </div>
                                    <div className="data-grid-box">
                                        <div className="data-grid-row">
                                            <span className='name'>کد پیگیری</span>
                                            <span>{selected.trackingCode}</span>
                                        </div>
                                        {
                                            selected.batchNumber && <div className="data-grid-row">
                                                <span className='name'>بچ نامبر</span>
                                                <span>{selected.batchNumber}</span>
                                            </div>
                                        }


                                        <div className="data-grid-row">
                                            <span className='name'>آدرس تراکنش</span>
                                            <span>{selected.txId}</span>
                                        </div>
                                        <div className="data-grid-row">
                                            <span className='name'>کد ووچر</span>
                                            <span>{selected.voucherCode}</span>
                                        </div>
                                        <div className="data-grid-row">
                                            <span className='name'>فعالساز</span>
                                            <span>{selected.voucherActivationCode}</span>
                                        </div>
                                        {
                                            selected.commission && <div className="data-grid-row">
                                                <span className='name'>کارمزد شبکه</span>
                                                <span>{selected.commission} TRX</span>
                                            </div>
                                        }
                                        {
                                            selected.networkFee && <div className="data-grid-row">
                                                <span className='name'>کارمزد صرافی</span>
                                                <span>${selected.networkFee}</span>
                                            </div>
                                        }

                                    </div>
                                    {/* <Button onClick={() => console.log('todo')} type={'dark'} text='مشاهده جزئیات تراکنش' /> */}

                                </>
                            }
                        </div>
                    </>
                }
                title=''
            />
            <div className={`ns-history`}>
                <Headline title='تاریخچه' description='از این بخش امکان مشاهده و بررسی تراکنش های واریز، برداشت و تبدیل خود را دارید.' />

                <div className="transaction-list">
                    {
                        loading ?
                            <Spin style={{ "marginTop": "64px" }} spinning={true} /> :
                            orders && orders!?.length > 0 ?
                                orders?.map((order: Order) => {
                                    return <div onClick={() => { setSelected(order); setIsOpen(!isOpen) }} className='transaction-item'>
                                        <div className="right-side">
                                            {
                                                SwapIconLight
                                            }
                                            <div className='type'>
                                                <span>تبدیل</span>
                                                {
                                                    order.txId ?
                                                        <span>{order.txId.substring(0, 4).toUpperCase()}...{order.txId.substring(order.txId.length - 4, order.txId.length).toUpperCase()}</span> :
                                                        <span></span>
                                                }
                                            </div>
                                        </div>

                                        <div className="left-side">
                                            <div className={`amounts out`}>
                                                <span>- {order.destinationAmount} {order.destinationMarket}</span>
                                                <span>+ {order.sourceAmount} {order.sourceMarket}</span>
                                            </div>
                                        </div>

                                    </div>
                                }) :
                                <Empty description='تاریخچه‌ای یافت نشد.' />

                    }


                </div>
            </div >
        </>
    );

};
