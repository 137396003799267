import * as React from 'react';
import './game.scss';
import { Headline } from 'components/headline/headline';
import { NeedSwapDrawer } from 'components/drawer/drawer';
import { useNavigate } from 'react-router-dom';

type Props = {

}
export const Game: React.FC<Props> = (props) => {

    const [isOpen, setIsOpen] = React.useState<boolean>(true);
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!isOpen) {
            navigate('/')
        }
    }, [isOpen]);
    return (
        <>
            <NeedSwapDrawer
                isOpen={true}
                setIsOpen={setIsOpen}
                content={
                    <>
                        <div className="exchange-selection">
                            <div className="text-center headline">
                                <p>این قسمت بزودی تکمیل خواهد شد.</p>
                            </div>

                        </div>
                    </>
                }
                title={'بازی'}
            />
            <div className={`ns-game`}>
                <Headline title='بازی' description='از این قسمت بازی کنید و سکه جمع کنید، بزودی تکمیل خواهد شد.' />
            </div >
        </>
    );

};
