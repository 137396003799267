import * as React from 'react';
import './profile.scss';
import { Box } from 'components/box/box';
import { DownloadOutlined, EditFilled, EditOutlined, EditTwoTone, LinkOutlined, LockOutlined, MessageOutlined, ScanOutlined, SecurityScanOutlined, SwapOutlined, UploadOutlined, UserOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { Empty, Spin } from 'antd';
import { Headline } from 'components/headline/headline';
import TelegramLogin from 'components/telegram/login/telegram-login';
import { NeedSwapDrawer } from 'components/drawer/drawer';
import { Input } from 'components/input/input';
import { Button } from 'components/button/button';
import { getMineProfile, updateEmail } from 'core/repositories/users/login';
import { MineProfile } from 'core/domains/users/user';
import profileBanner from 'assets/images/profile-banner.jpg'

type Props = {

}

export const Profile: React.FC<Props> = (props) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [email, setEmail] = React.useState<string>(localStorage.getItem('email') ? localStorage.getItem('email')!.toString() : '');

    const [profile, setProfile] = React.useState<MineProfile>();

    React.useEffect(() => {
        getMineProfile(setLoading, setProfile)
    }, [])

    return (
        <>
            <NeedSwapDrawer
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                content={
                    <>
                        <div className="update-email-drawer">
                            <Input
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder='ایمیل خود را وارد کنید'
                                autoFocus={true}
                                dir={email === '' ? 'rtl' : 'ltr'}
                                name={'email'}
                                type={'text'}
                            />
                            <Button loading={loading} onClick={() => {
                                updateEmail(setLoading, email)
                                setIsOpen(false)
                                getMineProfile(setLoading, setProfile);
                            }} type='swap' text='ثبت' />
                        </div>
                    </>
                }
                title=''
            />
            <div className={`ns-profile`}>
                <Headline title='پروفایل' description='اطلاعات کاربری خود را از این قسمت مشاهده و ویرایش کنید.' />
                {
                    loading ?
                        <Spin style={{ "margin": "64px 0px" }} spinning={true} /> :
                        profile && <div className="data-grid-box">
                            <div className="data-grid-row">
                                <span className='name'>نام کاربری</span>
                                <span>{localStorage.getItem('name') === 'null' ? '-' : localStorage.getItem('name')}</span>
                            </div>
                            <div className="data-grid-row">
                                <span className='name'>شناسه کاربری</span>

                                <span>{profile.chatId}</span>
                            </div>
                            <div className="data-grid-row">
                                <span className='name'>آدرس ایمیل</span>
                                <span>{profile.email} <EditOutlined onClick={() => setIsOpen(true)} /></span>
                            </div>
                            <div className="data-grid-row">
                                <span className='name'>تعداد سفارشات</span>
                                <span>{profile.orderCount}</span>
                            </div>
                        </div>
                }
                <img className='main-banner' src={profileBanner} />
                {/* <div className="connected-exchanges">
                    <h4>صرافی های متصل</h4>
                    <div className="grid-row">
                        <div className="grid-col">
                            <img src={'/exchanges/wallex.jpeg'} alt="" />
                            <span>والکس</span>
                        </div>
                        <div className="grid-col">
                            <img src={'/exchanges/pmxchange.jpeg'} alt="" />
                            <span>پی ام ایکسچنج</span>
                        </div>
                        <div className="grid-col">
                            <img src={'/exchanges/xpay.jpeg'} alt="" />
                            <span>ایکس پی</span>
                        </div>
                        <div className="grid-col">
                            <img src={'/exchanges/tetherland.png'} alt="" />
                            <span>تترلند</span>
                        </div>
                    </div>
                </div>
                <TelegramLogin /> */}
            </div >
        </>

    );

};
