import React, { DispatchWithoutAction, FC, useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import {
  BackButton,
  useThemeParams,
  useShowPopup,
  WebAppProvider,
  MainButton,
} from '@vkruglikov/react-telegram-web-app';
import { Button, ConfigProvider, theme } from 'antd';
import 'antd/dist/reset.css';

import './styles/global.scss'
import './index.css';
import useBetaVersion from './useBetaVersion';
import { NeedSwap } from 'routes/needswap';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { store } from 'core/redux/store';
import { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { login, signUp } from 'core/repositories/users/login';
import { UserProfile } from 'core/domains/users/user';

const DemoApp: FC<{
  onChangeTransition: DispatchWithoutAction;
}> = ({ onChangeTransition }) => {
  const [colorScheme, themeParams] = useThemeParams();
  const [isBetaVersion, handleRequestBeta] = useBetaVersion(false);
  const [activeBtn, setActiveBtn] = useState(true);

  const showPopup = useShowPopup();

  return (
    <div>
      <ConfigProvider
        theme={
          themeParams.text_color
            ? {
              algorithm:
                colorScheme === 'dark'
                  ? theme.darkAlgorithm
                  : theme.defaultAlgorithm,
              token: {
                colorText: themeParams.text_color,
                colorPrimary: themeParams.button_color,
                colorBgBase: themeParams.bg_color,
              },
            }
            : undefined
        }
      >
        <NeedSwap />
      </ConfigProvider>
    </div>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const App = () => {
  const [smoothButtonsTransition, setSmoothButtonsTransition] = useState(false);

  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<UserProfile>();

  useEffect(() => {
    const tg = (window as any).Telegram.WebApp;

    // Expand the WebApp
    tg.ready();
    tg.expand();

    // Extract initialization data
    const initData = tg.initDataUnsafe;

    if (initData && initData.user) {
      const userId = initData.user.id;
      const firstName = initData.user.first_name;
      const lastName = initData.user.last_name;
      const email = initData.user.id + '@gmail.com'; // Provide a default email if not available
      const fullName = `${firstName} ${lastName}`;

      login(setLoading, setUser, { chatId: userId, firstName: firstName, lastName: lastName, email: email })

      // Make a request to your backend to get the access token
    }
    else {


      console.error('Initialization data or user data is missing');
    }
  }, []);

  return (
    <Provider store={store}>

      <Helmet>
        {/* <link href={Fav} rel="icon" type="image/x-icon" />
        <script src={`${ONLINE_CHAT}`} type="text/javascript" />
        <script src={`${GTM}`} type="text/javascript" />

        <link href={Fav} rel="icon" />
        <link href={Fav} rel="icon" type="image/png" />
        <link href={Fav} rel="apple-touch-icon" />
        <link href={MANIFEST} rel="manifest" />

        <title>{APPNAME} | پلتفرم معاملاتی ارز های دیجیتال</title> */}

        <meta content="no-cache" http-equiv="cache-control" />
        <meta content="0" http-equiv="expires" />
        <meta content="no-cache" http-equiv="pragma" />
        <meta
          name="description"
          content="خرید و فروش اتوماتیک و آسان پرفکت مانی ، تتر و رمز ارز ها احراز هویت هوشمند و سریع زیر ۱ دقیقه بدون معطلی نرخ های رقابتی و بدون کارمزد"
        />
      </Helmet>
      <Toaster position="top-center" reverseOrder={false} />
      <WebAppProvider options={{ smoothButtonsTransition }}>
        {/* <MainButton /> */}
        {/* <BackButton onClick={() => navigate(-1)} /> */}
        {
          user && <DemoApp
            onChangeTransition={() => setSmoothButtonsTransition(state => !state)}
          />
        }
      </WebAppProvider>
    </Provider>
  );
};

root.render(<App />);
