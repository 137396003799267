import * as React from 'react';
import './swap.scss';
import { InfoIcon, ScanIcon, SwapBoxIcon } from 'assets/icons/svgs/icons';
import { Input } from 'components/input/input';
import { BalanceItem, SwapItem } from 'core/domains/swap';
import { Button } from 'components/button/button';
import { CoinsList } from 'components/coins-list/coins-list';
import { NeedSwapDrawer } from 'components/drawer/drawer';
import { ConfigProvider, message, Tooltip, Tour, TourProps } from 'antd';
import { Headline } from 'components/headline/headline';
import { getIconPath } from 'utils/scripts/market';
import { SellPm } from '../sell-pm/sell-pm';
import { useNavigate } from 'react-router-dom';
import { BackButton } from 'components/button/back/back-button';

type Props = {

}


const coins = [
    {
        id: 1,
        name: 'پرفکت مانی',
        symbol: 'PM',
        icon: <img src={`${getIconPath('PM')}`} />,
        change: +0.12,
        price: 1,
    },
    {
        id: 2,
        name: 'ترون',
        symbol: 'TRX',
        icon: <img src={`${getIconPath('TRX')}`} />,
        change: +6.11,
        price: 134.62,
    },

]

const exchanges = [
    {
        id: 1,
        name: 'والکس',
        icon: <img src={'/exchanges/wallex.jpeg'} />,
        enable: false,
    },
    {
        id: 1,
        name: 'پی ام ایکسچنج',
        icon: <img src={'/exchanges/pmxchange.jpeg'} />,
        enable: true,
    },
    {
        id: 1,
        name: 'ایکس پی',
        icon: <img src={'/exchanges/xpay.jpeg'} />,
        enable: false,
    },
    {
        id: 1,
        name: 'تترلند',
        icon: <img src={'/exchanges/tetherland.png'} />,
        enable: false,
    },
]

export const Swap: React.FC<Props> = (props) => {

    const [stage, setStage] = React.useState<'swap' | 'sellpm' | 'buypm'>('swap');

    const [exchange, setExchange] = React.useState<number>(0);

    const [exchangesIsOpen, setExchangesIsOpen] = React.useState<boolean>(false);

    const [soruceIsOpen, setSourceIsOpen] = React.useState<boolean>(false);
    const [destinationIsOpen, setDestinationIsOpen] = React.useState<boolean>(false);

    const [loading, setLoading] = React.useState<boolean>(false);

    const ref1 = React.useRef(null);
    const ref2 = React.useRef(null);
    const ref3 = React.useRef(null);
    const ref4 = React.useRef(null);
    const ref5 = React.useRef(null);

    const [tourIsOpen, setTourIsOpen] = React.useState<boolean>(false);

    const [balances, setBalances] = React.useState<BalanceItem[]>([
        {
            symbol: 'PM',
            amount: 0
        },
        {
            symbol: 'TRX',
            amount: 0
        },
    ]);


    React.useEffect(() => {
        setTourIsOpen(true)
    }, []);

    const [swap, setSwap] = React.useState<SwapItem>({
        source: {
            amount: 0,
            symbol: 'PM',
            name: 'پرفکت مانی',
            icon: <img src={`${getIconPath('PM')}`} />
        },
        destination: {
            amount: 0,
            symbol: 'TRX',
            name: 'ترون',
            icon: <img src={`${getIconPath('TRX')}`} />
        },
        fee: 0,
    });


    const steps: TourProps['steps'] = [
        {
            title: 'نوع ارز پرداختی',
            description: 'با زدن روی این گزینه می‌توانید نوع رمزارز پرداختی خود را تغییر دهید.',
            target: () => ref1.current,
        },
        {
            title: 'نوع ارز دریافتی',
            description: 'با زدن روی این گزینه می‌توانید نوع رمزارز دریافتی خود را تغییر دهید.',
            target: () => ref2.current,
        },
        {
            title: 'مقدار ارز پرداختی',
            description: 'با زدن روی این گزینه می‌توانید نوع رمزارز دریافتی خود را تغییر دهید.',
            target: () => ref3.current,
        },
        {
            title: 'بررسی کارمزد تراکنش',
            description: 'با زدن روی این گزینه می‌توانید نوع رمزارز دریافتی خود را تغییر دهید.',
            target: () => ref4.current,
        },
        {
            title: 'تبدیل',
            description: 'در نهایت در صورتی که با نرخ تبدیل موافق هستید٬ می‌توانید با زدن روی این گزینه٬ تبدیل خود را نهایی کنید.',
            target: () => ref5.current,
        },
    ];


    const Diversion = () => {
        const src = swap.source;
        const dest = swap.destination;
        setSwap({ ...swap, destination: src, source: dest })
    }

    const navigate = useNavigate();

    return (
        <>
            <BackButton />
            <NeedSwapDrawer
                isOpen={exchange === 0}
                setIsOpen={setExchangesIsOpen}
                content={
                    <>
                        <div className="exchange-selection">
                            <div className="text-center headline">
                                <h3>کدوم صرافی احراز هویت کردی قبلا ؟</h3>
                                <p>اگه هیچ جا تاحالا احراز نشدی یکی از صرافی های زیر رو انتخاب کن.</p>
                            </div>
                            <Button text='آموزش اتصال به صرافی' onClick={() => navigate('/learn-pmxchange')} type='swap' />
                            <div className="connected-exchanges">
                                <div className="grid-row">
                                    {
                                        exchanges.map((exchange) => {
                                            return <div onClick={() => exchange.enable ? navigate('/learn-pmxchange') : message.info('این بخش بزودی تکمیل خواهد شد.')} className={`grid-col ${exchange.enable ? '' : 'disabled'}`}>
                                                {exchange.icon}
                                                <span>{exchange.name}</span>
                                            </div>
                                        })
                                    }


                                </div>
                            </div>
                        </div>
                    </>
                }
                title={ScanIcon}
            />
            <NeedSwapDrawer
                isOpen={soruceIsOpen}
                setIsOpen={setSourceIsOpen}
                content={
                    <>
                        <CoinsList />
                    </>
                }
                title='ارز مورد نظر خود را انتخاب کنید'
            />
            <NeedSwapDrawer
                isOpen={destinationIsOpen}
                setIsOpen={setDestinationIsOpen}
                content={
                    <>
                        <CoinsList />
                    </>
                }
                title='ارز مورد نظر خود را انتخاب کنید'
            />
            {/* <Tour open={tourIsOpen} onClose={() => setTourIsOpen(false)} mask={false} type="primary" steps={steps} /> */}
            {
                stage === 'swap' ?
                    <div className={`ns-swap`}>
                        {
                            exchange !== 0 &&
                            <>
                                <Headline title='تبدیل سریع' description='از این بخش امکان تبدیل رمزارز های خود را به یکدیگر دارید.' />
                                <div className="swap-wrapper">
                                    <div className="wrapper source-wrapper">
                                        <div className="wrapper-body">
                                            <div className="right-side">
                                                <span ref={ref3}>پرداختی شما</span>
                                                <Input
                                                    value={swap.source.amount === 0 ? '' : swap.source.amount.toString()}
                                                    dir='rtl'
                                                    autoFocus
                                                    type='text'
                                                    maxLength={8}
                                                    onChange={(e) => Number.isInteger(Number(e.target.value)) ?
                                                        setSwap({ ...swap, source: { ...swap.source, amount: e.target.value !== '' ? parseInt(e.target.value) : 0 } }) :
                                                        e.target.value === '' && setSwap({ ...swap, destination: { ...swap.destination, amount: 0 } })}
                                                    name={'source'}
                                                />
                                            </div>
                                            <div className="left-side">
                                                <span>
                                                    <span>موجودی :</span>
                                                    <span className='balance'>
                                                        <span>{balances?.filter(s => s.symbol == swap.source.symbol)[0].amount}</span>
                                                        <span>{swap.source.symbol}</span>
                                                    </span>
                                                </span>
                                                <span ref={ref1} className='select'>
                                                    {swap.source.symbol}
                                                    {coins.filter(s => s.symbol === swap.source.symbol)[0].icon}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div onClick={() => Diversion()} className="fixed-swap-icon">
                                        {SwapBoxIcon}
                                    </div>
                                    <div className="wrapper destination-wrapper">
                                        <div className="wrapper-body">
                                            <div className="right-side">
                                                <span>دریافتی شما</span>
                                                <Input
                                                    value={swap.destination.amount === 0 ? '' : swap.destination.amount.toString()}
                                                    dir='rtl'
                                                    type='text'
                                                    maxLength={8}
                                                    onChange={(e) => Number.isInteger(Number(e.target.value)) ?
                                                        setSwap({ ...swap, destination: { ...swap.destination, amount: e.target.value !== '' ? parseInt(e.target.value) : 0 } }) :
                                                        e.target.value === '' && setSwap({ ...swap, destination: { ...swap.destination, amount: 0 } })}
                                                    name={'destination'}
                                                />
                                            </div>
                                            <div className="left-side">
                                                <span>
                                                    <span>موجودی :</span>
                                                    <span className='balance'>
                                                        <span>{balances?.filter(s => s.symbol == swap.destination.symbol)[0].amount}</span>
                                                        <span>{swap.destination.symbol}</span>
                                                    </span>
                                                </span>
                                                <span ref={ref2} className='select'>
                                                    {swap.destination.symbol}
                                                    {coins.filter(s => s.symbol === swap.destination.symbol)[0].icon}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="commission-wrapper">
                                            <div ref={ref4} className="right-side">
                                                <span>کارمزد :</span>
                                                <span> 0.021 TON</span>
                                            </div>
                                            <div className="left-side">
                                                <span>1 TRX ≈</span>
                                                <span>  0.13 USDT </span>
                                            </div>
                                        </div>

                                    </div>
                                    <Button ref={ref5} loading={loading} text={` تبدیل ${swap.source.name} به ${swap.destination.name}`} type='swap' onClick={() => swap.source.symbol === 'PM' ? setStage('sellpm') : setStage('buypm')} />
                                </div>
                            </>
                        }
                    </div >
                    : stage === 'sellpm' ?
                        <SellPm /> : <></>
            }
        </>
    );

};
