import * as React from 'react';
import './menu.scss';
import Dashboard from 'routes/dashboard/dashboard';
import { BarChartOutlined, HistoryOutlined, HomeOutlined, PlusOutlined, ProfileOutlined, ShopOutlined, StarOutlined, SwapOutlined, TrademarkOutlined, UserOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { ExchangeIcon, GameIcon, HistoryIcon, HomeIcon, ProfileIcon } from 'assets/icons/svgs/icons';


type Props = {

}


const menuItems = [

    {
        id: 3,
        title: 'بازی',
        icon: GameIcon,
        url: '/game'
    },
    {
        id: 2,
        title: 'آموزش',
        icon: ExchangeIcon,
        url: '/swap'
    },
    {
        id: 1,
        // title: 'منو اصلی',
        icon: HomeIcon,
        url: '/'
    },
    {
        id: 4,
        title: 'تاریخچه',
        icon: HistoryIcon,
        url: '/history'
    },
    {
        id: 5,
        title: 'پروفایل',
        icon: ProfileIcon,
        url: '/profile'
    },
]
export const NavbarMenu: React.FC<Props> = (props) => {

    const [active, setActive] = React.useState<number>(1);
    let location = useLocation();
    React.useEffect(() => {
        var item = menuItems.filter((s) => s.url.split('/')[1].toLowerCase() == location.pathname.split('/')[1])[0];

        setActive(item ? item.id : menuItems.filter((s) => s.id === 1)[0].id)
    }, [])

    const navigator = useNavigate();

    return (
        <>
            <div className="ns-bottom-navbar">

                {
                    menuItems.map((item) => {
                        return <div onClick={() => navigator(item.url)} className='menu-item'>
                            <button onClick={() => setActive(item.id)} className={`${active == item.id ? (item.id === 1 ? 'active float' : 'active') : item.id === 1 ? 'float' : ''}`}>
                                {item.icon}
                            </button>
                            {
                                item.id !== 1 && <span className={`${active == item.id ? 'active title' : 'title'}`}>{item.title}</span>
                            }
                        </div>
                    })
                }


            </div>

        </>
    );

};
